import React, { useState, useRef, useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { lighten, darken } from "polished";
import { Formik } from "formik";
import * as Yup from "yup";
import nanoid from "nanoid";
import qs from "query-string";
import { Query, Mutation } from "@apollo/client/react/components";
import Context from "@common/website/components/context/shared_utils";
import { getPropertyPrice } from "@common/website/components/shared/utils";
import { Redirect } from "react-router-dom";

import hjortBg from "@common/website/assets/images/hjort_background.jpg";

import TyPage from "@common/website/components/shared/ty-page/ty-page";
import SmsPrompt from "@common/website/components/routes/properties/shared/register/sms_prompt";
import Status from "@common/website/components/routes/properties/shared/register/status";
import FormField from "@common/website/components/shared/form/lib/form_field";
import SubmitButton from "@common/website/components/shared/form/lib/submit_button";

import { PROPERTY_VALUATION_QUERY } from "@common/website/gql/queries/property-valuation.gql";
import { CREATE_LEAD_MUTATION } from "@common/website/gql/mutations/lead.gql";

import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { HttpLink } from "@apollo/client/link/http";
import fetch from "isomorphic-unfetch";

const STAGING = process.env.RELEASE === "staging";
const PRODUCTION = process.env.NODE_ENV === "production";

let client = null;
let cache = new InMemoryCache();
if (typeof window !== "undefined") {
	cache.restore(window.__APOLLO_STATE__);
	client = new ApolloClient({
		cache,
		name: "pm2019_web",
		link: ApolloLink.from([
			new HttpLink({
				headers: {
					"x-client-id": "pm2019_web"
				},
				uri: PRODUCTION
					? STAGING
						? "https://staging-pmgraph.reeltime.no/graphql"
						: "https://pmgraph.reeltime.no/graphql"
					: "https://staging-pmgraph.reeltime.no/graphql",
				credentials: "same-origin",
				fetch
			})
		])
	});
} else {
	client = new ApolloClient({
		cache,
		headers: {
			"x-client-id": "pm2019_web"
		},
		name: "pm2019_web",
		link: ApolloLink.from([
			new HttpLink({
				headers: {
					"x-client-id": "pm2019_web"
				},
				uri: PRODUCTION
					? STAGING
						? "https://staging-pmgraph.reeltime.no/graphql"
						: "https://pmgraph.reeltime.no/graphql"
					: "https://staging-pmgraph.reeltime.no/graphql",
				credentials: "same-origin",
				fetch: fetch
			})
		])
	});
}

const Register = ({ location, match }) => {
	const sharedUtils = useContext(Context);
	const formRef = useRef(null);
	const [showCheckSmsValue, setShowCheckSmsValue] = useState(false);
	const [checkedSmsValue, setCheckedSmsValue] = useState(false);

	return (
		<Query
			query={PROPERTY_VALUATION_QUERY}
			client={client}
			fetchPolicy={"no-cache"}
			variables={{
				input: {
					sguid: match.params.id,
					includeUnits: true,
					includeAccepted: true,
					sold: false
				}
			}}
		>
			{({ data, loading, error }) => {
				if (loading) {
					return null;
				} else if (!loading && error) {
					console.log(error);
					return <Redirect to="/404" />;
				} else if (!loading && data && data.getProperty) {
					let property = data.getProperty;
					if (data.getProperty.id !== match.params.id) {
						// må være undernummer
						property = data.getProperty.units.list.all.find(unit => unit.id === match.params.id);

						if (!property) {
							console.log("ikke undernummer");
							throw new Error("Feil oppstod");
						}
					}

					if (!data.getProperty.accepted) {
						return <Redirect to="/404" />;
					} else if (!data.getProperty.published) {
						return <Redirect to="/404" />;
					}

					const initialFormState = {
						values: {
							first_name: {
								placeholder: "Fornavn *",
								value: PRODUCTION ? "" : "Test",
								validation: Yup.string()
									.min(2, "Fornavnet må være minst to bokstaver")
									.required("Påkrevd")
							},
							last_name: {
								placeholder: "Etternavn *",
								value: PRODUCTION ? "" : "Reeltime",
								validation: Yup.string()
									.min(2, "Etternavnet må være minst to bokstaver")
									.required("Påkrevd")
							},
							address: {
								placeholder: "Adresse",
								value: PRODUCTION ? "" : "Testveien 1"
							},
							zip: {
								placeholder: "Postnr *",
								value: PRODUCTION ? "" : "1234",
								validation: Yup.string()
									.min(4, "Ugylding postnummer")
									.max(4, "Ugylding postnummer")
									.matches(/^[0-9]*$/, "Ugyldig postnummer")
									.required("Påkrevd")
							},
							phone: {
								placeholder: "Telefon *",
								value: PRODUCTION ? "" : "12345678",
								validation: Yup.string()
									.matches(/^\s*(\d\s*){8}$/, { excludeEmptyString: true, message: "Ugyldig telefonnummer" })
									.required("Påkrevd")
							},
							email: {
								placeholder: "Epost *",
								value: PRODUCTION ? "" : "martin@reeltime.no",
								validation: Yup.string()
									.email("Ugyldig epost")
									.required("Påkrevd")
							},
							comment: {
								placeholder: "Kommentar",
								value: "",
								validation: Yup.string()
							}
						},
						consents: {
							...(property.checklistpoints.info.teaser
								? {
									project_contact: {
										value: false,
										placeholder: "og dele min informasjon med utbygger",
										validation: Yup.boolean()
									}
								}
								: {
									...(property?.hovedoppdrag !== null
										? {
											project_contact: {
												value: false,
												placeholder: "og dele min informasjon med utbygger",
												validation: Yup.boolean()
											}
										}
										: {}),
									...(property?.hovedoppdrag === null
										? {
											search_agent: {
												value: false,
												placeholder:
													"om lignende eiendommer som kommer for salg og annen boligrelatert informasjon",
												validation: Yup.boolean()
											}
										}
										: {}),
									...(property?.hovedoppdrag === null
										? {
											kjop: {
												value: false,
												placeholder: "i forbindelse med kjøp av bolig",
												validation: Yup.boolean()
											}
										}
										: {})
								}),
							valuation: {
								value: false,
								placeholder: "i forbindelse med salg og verdivurdering/e-takst av min nåværende bolig",
								validation: Yup.boolean()
							},
							financing: {
								value: false,
								placeholder: "Jeg anmoder om å bli kontaktet av Nordea angående finansiering.",
								validation: Yup.boolean()
							}
						}
					};

					return (
						<>
							<Mutation mutation={CREATE_LEAD_MUTATION}>
								{createLead => (
									<Container>
										<Title>{property?.hovedoppdrag !== null ? "Meld interesse" : "Bestill salgsoppgave"}</Title>
										<SubTitle>{property?.address}</SubTitle>
										<SubTitle>
											{property?.price?.info?.price ? `Prisantydning: ${property?.price?.info?.price}` : null}
										</SubTitle>
										<Info style={{ marginTop: "1em" }}>
											Jeg ønsker å få tilsendt salgsoppgave og anmoder megler om å holde meg oppdatert i forbindelse med
											salget av denne eiendommen, på e-post og /eller telefon i form av sms eller oppringing.
											<br />
											<br />
											Dersom du ikke ønsker å bli kontaktet finner du komplett salgsoppgave for eiendommen tilgjengelig{" "}
											<a href={`https://privatmegleren.no/${property.id}`}>her</a>.{" "}
										</Info>
										<Formik
											isInitialValid={!PRODUCTION}
											initialValues={{
												values: Object.entries(initialFormState.values).reduce(
													(acc, [k, v]) => ({
														...acc,
														[k]: v.value
													}),
													{}
												),
												consents: Object.entries(initialFormState.consents).reduce(
													(acc, [k, v]) => ({
														...acc,
														[k]: v.value
													}),
													{}
												)
											}}
											validationSchema={Yup.object().shape({
												values: Yup.object().shape(
													Object.entries(initialFormState.values).reduce(
														(acc, [k, v]) => ({
															...acc,
															...(v.validation && {
																[k]: v.validation
															})
														}),
														{}
													)
												),
												consents: Yup.object().shape(
													Object.entries(initialFormState.consents).reduce(
														(acc, [k, v]) => ({
															...acc,
															...(v.validation && {
																[k]: v.validation
															})
														}),
														{}
													)
												)
											})}
											onSubmit={(values, actions) => {
												/*
												if (
													checkedSmsValue ||
													values.consents.bid_updates_sms ||
													data.getProperty.hovedoppdrag !== null
												) {
													setShowCheckSmsValue(false);
													setCheckedSmsValue(false);
													*/

												window.scrollTo({
													top: 0,
													left: 0,
													behavior: "smooth"
												});

												setTimeout(() => {
													actions.setStatus("success");
												}, 480);

												setTimeout(() => {
													actions.setSubmitting(false);
												}, 500);

												if (values.consents.financing) {
													setTimeout(() => {
														actions.resetForm();
													}, 7000);
												}

												const { test_id } = qs.parse(location.search);
												values.consents.newsletter = false;
												if (values.consents.search_agent) {
													values.consents.newsletter = true;
												}

												if (data?.getProperty?.hovedoppdrag === null) {
													values.consents.bid_updates_sms = true;
												}

												const submit = diggerId => {
													const responsibleBroker =
														data.getProperty.brokers && data.getProperty.brokers.list.filter(item => item.responsible);
													createLead({
														variables: {
															input: {
																allow_performance: window.allowPerformance ? window.allowPerformance : false,
																allow_marketing: window.allowMarketing ? window.allowMarketing : false,
																sguid: property.id,
																digger_id: diggerId ? diggerId : null,
																recipient:
																	data.getProperty.brokers &&
																		data.getProperty.brokers.list &&
																		data.getProperty.brokers.list[0] &&
																		data.getProperty.brokers.list[0].email
																		? data.getProperty.brokers.list[0].email
																		: null,
																identifier:
																	responsibleBroker && responsibleBroker[0] && responsibleBroker[0].wmId
																		? responsibleBroker[0].wmId.toString()
																		: data.getProperty.brokers &&
																		data.getProperty.brokers.list &&
																		data.getProperty.brokers.list[0] &&
																		data.getProperty.brokers.list[0].wmId.toString(),
																valuation_type: "broker",
																src: property.hovedoppdrag !== null ? "Meld interesse" : "Bestill salgsoppgave",
																referrer: data.getProperty.id
																	? `https://privatmegleren.no/${property.hovedoppdrag !== null ? "meldinteresse" : "registrerinteressent"
																	}/${property.id}`
																	: null,
																values: {
																	...values.values,
																	comment:
																		values.values.comment && values.values.comment !== values.values.email
																			? values.values.comment
																			: ""
																},
																consents: values.consents,
																...(test_id
																	? {
																		test: {
																			id: test_id,
																			test: true
																		}
																	}
																	: {})
															}
														}
													});
												};

												sharedUtils.digger
													.formSubmit(
														values.values.zip
															? {
																zip: values.values.zip,
																type: "Salgsoppgave",
																"Smt VV": values.consents.valuation ? true : false,
																"Smt BT": values.consents.general_contact ? true : false,
																"Smt F": values.consents.financing ? true : false,
																"Smt N": values.consents.newsletter ? true : false,
																"Smt NN": values.consents.nybygg_newsletter ? true : false,
																"Smt L": values.consents.search_agent ? true : false,
																"Smt BV": values.consents.bid_updates_sms ? true : false,
																"Smt kjop": values.consents.kjop ? true : false
															}
															: {}
													)
													.then(async res => {
														let id = res.result && res.result.id ? res.result.id : null;
														console.log("here!!", id);
														submit(id);
													})
													.catch(() => submit());
												/* } else {
													console.log("CHECK FOR SMS CONSENT");
													console.log(values);
													window.scrollTo({
														top: 0,
														left: 0,
														behavior: "smooth"
													});
													return setShowCheckSmsValue(true);
												} */
											}}
											render={({
												handleSubmit,
												handleChange,
												handleBlur,
												setFieldValue,
												values,
												touched,
												errors,
												isValid,
												isSubmitting,
												status,
												resetForm
											}) => (
												<Form onSubmit={handleSubmit} ref={formRef}>
													{status === "success" || isSubmitting ? (
														<TyPage
															close={() => {
																resetForm();
															}}
															isSubmitting={isSubmitting}
															status={status}
															financingText={values.consents.financing}
														/>
													) : null}
													{showCheckSmsValue ? (
														<SmsPrompt form={formRef} setCheckedSmsValue={setCheckedSmsValue} />
													) : null}
													{Object.entries(values.values)
														.filter(([k]) => k !== "comment")
														.map(([k, v]) => (
															<FormField
																key={k}
																fieldName={k}
																type="text"
																name={`values.${k}`}
																placeholder={initialFormState.values[k]?.placeholder}
																touched={touched && touched.values && touched.values[k] ? true : false}
																error={errors && errors.values && errors.values[k]}
																value={values.values[k]}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
														))}
													<Consents>
														<ConsentsTitle>
															Jeg anmoder PrivatMegleren om å kontakte meg på e-post og /eller telefon i form av sms
															eller oppringing.
														</ConsentsTitle>
														{Object.entries(values.consents).map(([k, v]) => (
															<FormField
																key={k}
																fieldName={k}
																type="checkbox"
																name={`consents.${k}`}
																label={initialFormState.consents[k]?.placeholder}
																placeholder={initialFormState.consents[k]?.placeholder}
																touched={touched && touched.consents && touched.consents[k] ? true : false}
																error={errors && errors.consents && errors.consents[k]}
																value={values.consents[k]}
																onChange={handleChange}
																onBlur={handleBlur}
															/>
														))}
													</Consents>
													<FormField
														key={"comment"}
														fieldName={"comment"}
														type="text"
														name={`values.comment`}
														placeholder={initialFormState.values.comment?.placeholder}
														touched={touched && touched.values && touched.values.comment ? true : false}
														error={errors && errors.values && errors.values.comment}
														value={values.values.comment}
														onChange={handleChange}
														onBlur={handleBlur}
													/>
													<Info>
														<a href="https://privatmegleren.no/personvern">Personvernpolicy</a>
													</Info>
													<SubmitButton isValid={isValid ? true : false} />
												</Form>
											)}
										/>
										<Questions>Har du spørsmål?</Questions>
										<Brokers>
											{data &&
												data.getProperty &&
												data.getProperty.brokers &&
												data.getProperty.brokers.list &&
												data.getProperty.brokers.list.length
												? data.getProperty.brokers.list.map((b, i) => (
													<Broker key={`broker_${i}`}>
														<BrokerLink href={`https://privatmegleren.no/megler/${b.path}`}>
															<BrokerAvatar bg={b?.image?.full} />
															<BrokerContent>
																<h4>{b.name}</h4>
																<p>{b.title}</p>
																<p>
																	{data.getProperty.office &&
																		data.getProperty.office.info &&
																		data.getProperty.office.info.name
																		? `PrivatMegleren ${data.getProperty.office.info.name}`
																		: null}
																</p>
															</BrokerContent>
														</BrokerLink>
													</Broker>
												))
												: null}
										</Brokers>
									</Container>
								)}
							</Mutation>
							<ContainerBackground />
						</>
					);
				}
			}}
		</Query>
	);
};

const Container = styled.div`
    display; block;
    width: 768px;
    margin: 0 auto;
    padding: 154px 18px 72px 18px;

		@media screen and (max-width: 960px) {
			max-width: 100%;
		}
`;

const ContainerBackground = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-image: url(${hjortBg});
	background-position: center 5%;
	background-repeat: no-repeat;
	background-size: 100%;
	z-index: -1;
	opacity: 0.333;
`;

const Title = styled.h1`
	text-align: center;

	@media screen and (max-width: 960px) {
		font-size: 48px;
	}
`;

const SubTitle = styled.h3`
	margin: 0;
	text-align: center;
	color: #fff;
	font-family: "Proxima Nova";
	font-weight: 500;
`;

const Questions = styled(SubTitle)`
	margin: 48px 0 18px 0;
	color: ${({ theme }) => theme.colors.gold.primary};
`;

const Form = styled.form`
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	margin-top: 1em;

	button {
		width: 100%;
		font-size: 24px;
	}
`;

const Consents = styled.div`
	display: flex;
	flex-flow: column;
	width: 100%;
	padding: 24px;
	margin-bottom: 12px;
	background: ${({ theme }) => theme.colors.grey.darkest};
`;

const ConsentsTitle = styled.h4`
	margin: 0 0 24px 0;
	font-weight: 500;
	font-size: 21px;
`;

const Info = styled.div`
	max-width: 75ch;
	padding: 0 0 15px;
	margin: 0 auto;
	text-align: center;
	color: #fff;
	font-size: 16px;
	a {
		color: ${({ theme }) => theme.colors.gold.primary};
	}
`;

const PromptTitle = styled.h1`
	font-size: 42px;
	line-height: 1.25;
`;

const PromptButtons = styled.div`
	display: flex;
	flex-flow: row nowrap;
`;

const PromptButton = styled.button`
	min-width: 150px;
	height: 48px;
	padding: 9px 18px;
	margin: 3px 6px;
	border: 0;
	background: ${({ theme }) => theme.colors.grey.dark};
	color: #fff;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	&:hover {
		background: ${({ theme }) => lighten(0.05, theme.colors.grey.dark)};
	}

	&:last-of-type {
		background: ${({ theme }) => theme.colors.gold.primary};
		color: #000;
		font-weight: 600;

		&:hover {
			background: ${({ theme }) => darken(0.075, theme.colors.gold.primary)};
		}
	}
`;

const Brokers = styled.ul`
	display: block;
	margin: 24px 0 0 0;
	padding: 0;
	list-style: none;
`;

const Broker = styled.li`
	display: block;
	width: 100%;
`;

const BrokerLink = styled.a`
	display: flex;
	flex-flow: row nowrap;
	width: 100%;
	padding: 18px;
	margin-bottom: 3px;
	background: ${({ theme }) => theme.colors.grey.darkest};
	color: #fff;
	text-decoration: none;

	&:hover {
		background: ${({ theme }) => theme.colors.grey.dark};
	}
`;

const BrokerAvatar = styled.div`
	position: relative;
	display: inline-block;
	min-width: 15%;
	max-width: 15%;
	border-radius: 50%;
	overflow: hidden;
	background: #fff;

	&:before {
		content: "";
		display: block;
		padding-top: 100%;
		background: ${({ bg }) => `url(${bg})`};
		background-size: cover;
		background-position: center -12px;
	}

	@media screen and (max-width: 960px) {
		display: none;
	}
`;

const BrokerContent = styled.div`
	position: relative;
	display: inline-flex;
	flex-flow: column;
	justify-content: center;
	vertical-align: top;
	flex: 1;
	padding-left: 24px;

	h4 {
		margin: 0 0 6px 0;
		font-size: 21px;
		color: ${({ theme }) => theme.colors.gold.primary};
		font-weight: 500;
	}
	p {
		margin: 0;
		line-height: 1.25;
	}

	&:before {
		position: absolute;
		content: "";
		display: block;
		padding-top: 100%;
	}

	@media screen and (max-width: 960px) {
		padding-left: 0;
		justify-content: center;
		text-align: center;
	}
`;

Register.propTypes = {
	location: PropTypes.any,
	match: PropTypes.any
};

export default Register;
