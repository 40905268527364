import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "formik";
import styled, { css } from "styled-components";
import { lighten, darken } from "polished";

const SmsPrompt = ({ form, setCheckedSmsValue, formik }) => {
	return (
		<Container id="form-sms-prompt">
			<Inner>
				<PromptTitle>
					Ønsker du <br />
					budvarsel på SMS?
				</PromptTitle>
				<PromptButtons>
					<PromptButton
						id="prompt-button-no"
						onClick={() => {
							formik.setFieldValue("consents.bid_updates_sms", false);
							setCheckedSmsValue(true);
							formik.handleSubmit();
						}}
					>
						Nei
					</PromptButton>
					<PromptButton
						onClick={() => {
							formik.setFieldValue("consents.bid_updates_sms", true);
							setCheckedSmsValue(true);
							formik.handleSubmit();
						}}
					>
						Ja
					</PromptButton>
				</PromptButtons>
			</Inner>
		</Container>
	);
};

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	display: block;
	width: 100%;
	min-height: 100%;
	background: rgba(0, 0, 0, 0.95);
	z-index: 999;
`;

const Inner = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-flow: column;
	width: 100%;
	height: 100%;
	text-align: center;
	z-index: 12;
`;

const PromptTitle = styled.h1`
	font-size: 42px;
	line-height: 1.25;
`;

const PromptButtons = styled.div`
	display: flex;
	flex-flow: row nowrap;
`;

const PromptButton = styled.button`
	min-width: 150px;
	height: 48px;
	padding: 9px 18px;
	margin: 3px 6px;
	border: 0;
	background: ${({ theme }) => theme.colors.grey.dark};
	color: #fff;
	cursor: pointer;

	&:focus {
		outline: 0;
	}

	&:hover {
		background: ${({ theme }) => lighten(0.05, theme.colors.grey.dark)};
	}

	&:last-of-type {
		background: ${({ theme }) => theme.colors.gold.primary};
		color: #000;
		font-weight: 600;

		&:hover {
			background: ${({ theme }) => darken(0.075, theme.colors.gold.primary)};
		}
	}
`;

SmsPrompt.propTypes = {
	form: PropTypes.any,
	setCheckedSmsValue: PropTypes.func,
	formik: PropTypes.object
};

export default connect(SmsPrompt);
