export const formatNumber = num => {
	if (num && parseFloat(num)) {
		return num
			.toFixed(0)
			.replace(".", ",")
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
	} else {
		return 0;
	}
};
