/* eslint-disable jsx-a11y/media-has-caption */
import React, { Component, useState, useEffect, useRef, useContext } from "react";
import { withRouter } from "react-router-dom";
import styled, { css } from "styled-components";
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import MDSpinner from "react-md-spinner";
import qs from "query-string";
import Context from "@common/website/components/context/shared_utils";

import playIcon from "@common/website/assets/images/play.png";
import falkBg from "@common/website/assets/images/falk_bg_cutout.png";
import logoImg from "@common/website/assets/images/logo.png";
import mittBoligsalgLogoImg from "@common/website/assets/images/mittboligsalg_logo.png";
import mittBoligsalgScreen from "@common/website/assets/images/mitt_boligsalg_screen.png";
import closeIcon from "@common/website/assets/svg/close_icon.svg";

class TyPage extends Component {
	constructor(props) {
		super(props);
		this.scrollContainer = React.createRef();
		this.state = {
			success: null,
			isSubmitting: null,
			kind: null,
			scrollLocked: false,
			showVideo: false,
			videoEventSent: false
		};
	}

	componentDidMount() {
		if (window.pmlib) {
			window.pmlib.instance = this;
		}
		const { kind } = this.state;
		const { location } = this.props;
		const randomKind = Math.round(Math.random());

		if (location && location.search && !kind) {
			const { type } = qs.parse(location.search);
			if (type && type !== "klikk") {
				this.setState({
					kind: "mittboligsalg"
				});
			} else if (type && type === "klikk") {
				this.setState({
					kind: "klikk"
				});
			} else {
				this.setState({
					kind: "klikk"
				});
			}
		} else {
			if (randomKind === 0) {
				this.setState({
					kind: "klikk"
				});
			} else {
				this.setState({
					kind: "mittboligsalg"
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (!prevState.showVideo && this.state.showVideo && !this.state.videoEventSent) {
			this.setState(
				{
					videoEventSent: true
				},
				() => {
					if (window.dataLayer) {
						window.dataLayer.push({
							event: "ThankYouPage",
							category: "ThankYouPage",
							gaAction: "Play video",
							action: "Play video",
							videoType: this.state.kind
						});
					}
				}
			);
		}
	}

	componentWillUnmount() {
		clearAllBodyScrollLocks();
	}

	handleRef = ref => {
		if (!this.state.scrollLocked) {
			this.setState(
				{
					scrollLocked: true
				},
				() => {
					console.log("DISABLING SCROLL");
					this.scrollContainer = ref;
					disableBodyScroll(this.scrollContainer);
				}
			);
		}
	};

	setSuccess = val => {
		this.setState({ success: val });
	};

	setIsSubmitting = val => {
		this.setState({ isSubmitting: val });
	};

	render() {
		const { kind, scrollLocked, showVideo, videoEventSent } = this.state;
		const { status, isSubmitting, financingText, location, close } = this.props;
		return (
			<>
				<Container show={status} id="pm-ty-page">
					<Inner ref={this.handleRef}>
						<CloseIcon
							dangerouslySetInnerHTML={{ __html: closeIcon }}
							onClick={() => {
								this.setState(
									{
										scrollLocked: false
									},
									() => {
										clearAllBodyScrollLocks();
										close();
									}
								);
							}}
						/>
						{(this.state.isSubmitting !== null ? (
							this.state.isSubmitting
						) : (
							isSubmitting
						)) ? (
							<Spinner>
								<MDSpinner userAgent={null} size={60} singleColor="#e8c893" />
							</Spinner>
						) : (
							<>
								<Logo src={logoImg} />
								<Title>
									Takk for din <br />
									henvendelse
								</Title>
								<Text>
									{financingText ? (
										`Du vil om kort tid motta en SMS. Denne må besvares med ordet BEKREFT for å samtykke til at en rådgiver
							fra Nordea kontakter deg på telefonnummeret som du har oppgitt.`
									) : (
										<>
											Vi ser frem til å bistå deg. <br />
											<br />
											{kind && kind === "klikk" ? (
												<>
													Du kan lese mer om PrivatMegleren og vårt unike annonsesystem KLiKK{" "}
													<a
														href="https://privatmegleren.no/klikk"
														onClick={(e) => {

															if (window.dataLayer) {
																window.dataLayer.push({
																	event: "ThankYouPage",
																	category: "ThankYouPage",
																	gaAction: "Link click",
																	action: "Link click",
																	videoType: this.state.kind
																});
															}
														}}
													>
														her
													</a>{" "}
													- eller se video
												</>
											) : kind && kind !== "klikk" ? (
												<>
													Du kan lese mer om PrivatMegleren og Mitt Boligsalg{" "}
													<a
														href="https://privatmegleren.no/mittboligsalg"
														onClick={(e) => {

															if (window.dataLayer) {
																window.dataLayer.push({
																	event: "ThankYouPage",
																	category: "ThankYouPage",
																	gaAction: "Link click",
																	action: "Link click",
																	videoType: this.state.kind
																});
															}
														}}
													>
														her
													</a>{" "}
													- et produkt som gjør det mulig å følge boligsalget ditt live.
												</>
											) : null}
										</>
									)}
								</Text>
								{!financingText && (kind && kind !== "klikk") ? <MittBoligsalgLogo src={mittBoligsalgLogoImg} /> : null}

								{!financingText && kind ? (
									<>
										<VideoContainer kind={kind}>
											{showVideo ? (
												<VideoFrame
													src={`${
														kind === "klikk"
															? "https://player.vimeo.com/video/187138969"
															: "https://player.vimeo.com/video/202725084"
													}?autoplay=1&muted=1&color=FFF&title=0&byline=0&portrait=0`}
													frameBorder="0"
													allow="autoplay; fullscreen"
													allowFullScreen
												/>
											) : (
												<PlayButton onClick={() => this.setState({ showVideo: true })} />
											)}
										</VideoContainer>
									</>
								) : null}
								{!financingText ? <script src="https://player.vimeo.com/api/player.js" /> : null}
							</>
						)}
						<BackgroundImage />
					</Inner>
				</Container>
			</>
		);
	}
}

const CloseIcon = styled.a`
	position: absolute;
	top: 24px;
	right: 36px;
	display: flex;
	width: 24px;
	height: 24px;
	z-index: 999;

	svg {
		display: flex;
		width: 24px;
		height: 24px;

		path {
			fill: #fff;
		}
	}
`;

const Spinner = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	min-height: 100vh;
	background: rgba(0, 0, 0, 0.95);
	z-index: 998;
`;

const Inner = styled.div`
	position: relative;
	display: flex;
	flex-flow: column;
	align-items: center;
	text-align: center;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	padding: 12px 12px 200px 12px;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
`;

const Logo = styled.img`
	position: relative;
	display: block;
	width: 172px;
	margin: 24px 0 60px 0;

	&:before {
		position: relative;
		content: "";
		display: block;
		padding-top: 100%;
	}

	@media screen and (min-width: 768px) {
		width: 200px;
	}
`;

const MittBoligsalgLogo = styled(Logo)`
	width: 172px;
	margin-bottom: 24px;
`;

const VideoContainer = styled.div`
	position: relative;
	display: block;
	width: 100%;

	${({ kind }) =>
		kind && kind !== "klikk"
			? css`
					background: url(${mittBoligsalgScreen});
					background-size: cover;
					background-repeat: no-repeat;
			  `
			: null};

	&:before {
		position: relative;
		content: "";
		display: block;
		padding-top: 56.25%;
	}

	@media screen and (min-width: 768px) {
		max-width: 480px;
	}
`;

const PlayButton = styled.a`
	position: absolute;
	top: 50%;
	left: 50%;
	width: 40%;
	height: 40%;
	background: url(${playIcon});
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	transform: translate3d(-50%, -50%, 0);
	cursor: pointer;

	&:hover {
		width: 42.5%;
		height: 42.5%;
		transition: all ease-in-out 120ms;
	}
`;

const VideoFrame = styled.iframe`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
`;

const Title = styled.h1`
	line-height: 1.2;
	font-size: 36px;
	margin-bottom: 36px;

	@media screen and (min-width: 768px) {
		font-size: 42px;
	}
`;

const Text = styled.p`
	font-size: 18px;
	max-width: 45ch;

	a {
		color: #fff;
		font-weight: 600;
	}
`;

const BackgroundImage = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
	width: 100vh;
	background-attachment: fixed;
	background-size: 60vh;
	background-position: center right;
	background-repeat: no-repeat;
	background-image: url(${falkBg});
	z-index: -1;
	pointer-events: none;
	opacity: 0.65;
`;

export default withRouter(TyPage);
